<template>
  <div class="feed-list">
    <div
      v-if="hasPostsPlaceholder"
      class="text-xs-center placeholder"
    >
      <v-icon
        size="50"
        color="primary"
      >
        speaker_notes_off
      </v-icon>
      <p class="mt-2">
        {{ $t('views.feed_list.no_posts_at_moment') }}
      </p>
    </div>
    <div>
      <post-card
        v-for="(post, index) in postsList"
        :key="index"
        :post="post"
        @moreOptions="moreOptions"
        @postDetails="postDetails"
      />
    </div>
    <report-dialog
      :post-id="selectedPostId"
      :dialog="showReportDialog"
      @onCloseDialog="closeReportDialog"
    />
    <infinite-loading
      slot="append"
      spinner="waveDots"
      force-use-infinite-wrapper=".el-table__body-wrapper"
      @infinite="infiniteHandler"
      @distance="2"
    >
      <template slot="no-more">
        ~
      </template>
      <template slot="no-results">
        ~
      </template>
    </infinite-loading>
  </div>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading';
import PostCard from '@/components/cards/Post.vue';
import ReportDialog from '@/components/dialogs/ReportDialog.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'FeedList',
  components: {
    PostCard,
    ReportDialog,
    InfiniteLoading,
  },
  data() {
    return {
      posts: [],
      postId: null,
      reportDialog: false,
    };
  },
  computed: {
    ...mapGetters([
      'getTeacherPosts',
      'currentPage',
      'itemsPerPage',
      'currentTeacher',
    ]),
    showReportDialog() {
      return this.reportDialog;
    },
    selectedPostId() {
      return this.postId;
    },
    hasPostsPlaceholder() {
      return this.posts.length === 0;
    },
    postsList() {
      return this.posts;
    },
  },
  watch: {
    getTeacherPosts(value) {
      this.posts = value;
    },
  },
  mounted() {
    const navOptions = {
      options: {
        spacer: {
          has: true,
        },
        moreOptions: {
          has: true,
          actions: [
            {
              title: 'Perfil',
              method: this.showProfile,
            },
            {
              title: 'Sair',
              method: this.logoutTeacher,
            },
          ],
        },
      },
    };
    this.changeToolbarNav(navOptions);
    this.restartPagination();
    this.removeLoading();
    this.changeTab(this.$route.name);
  },
  destroyed() {
    this.clearPosts();
  },
  methods: {
    ...mapActions([
      'findTeacherPosts',
      'initPagination',
      'changeToolbarNav',
      'clearPosts',
      'logout',
      'restartPagination',
      'removeLoading',
      'changeTab',
    ]),
    moreOptions(postId) {
      this.postId = postId;
      this.reportDialog = true;
    },
    infiniteHandler($state) {
      this.initPagination($state);
      this.findTeacherPosts({
        page: this.currentPage,
        items: this.itemsPerPage,
      });
    },
    postDetails(post) {
      this.$router.push({
        name: 'postDetails',
        params: {
          postId: post.id,
          postSelected: post,
        },
      });
    },
    closeReportDialog() {
      this.reportDialog = false;
    },
    showProfile() {
      this.$router.push({
        name: 'profile',
      });
    },
    logoutTeacher() {
      const jwtToken = this.currentTeacher.jwt_token;
      this.$router.push({ name: 'logout', params: { jwt_token: jwtToken } });
      this.logout();
    },
  },
};
</script>

<style lang="scss">
  .feed-list {
    padding: 16px;
    @include font-monteserrat-bold;
    color: $color-primary;

    .no-more {
      @include font-monteserrat-bold;
      font-size: $font-size-extra-small;
    }

    .placeholder {
      text-align: center;
      @include font-monteserrat-bold;
      font-size: $font-size-extra-small;
    }

    .infinite-loading {
      width: 100%;
      height: 10px;
    }
  }
</style>
